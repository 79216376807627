import React, { useState, useEffect } from 'react';
import Tabletop from 'tabletop';

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}


var restDiff = null
var prevDiff = null
var prevTime = null

export default function App() {
  const [days, setdays] = useState([]);

  useEffect(() => {
    Tabletop.init({
      key: '1n73rIpfTj_G0hVK9P1QMWfMPRmcRt9AohcimgLUzB5E',
      simpleSheet: true,
      callback: googleData => {
        const data = googleData
          .filter(row => row["t1"] !== '')
          //.reverse()
          .map(row => {
            let times = []
            Object.keys(row).filter(key => key.charAt(0) === "t").forEach(key => {
              if(row[key].length > 3 && row[key].length < 6) times.push(row[key])
            })

            return {
              date: row.datum,
              times: times
            }
          });

        setdays(data)
      }
    })
  }, []);


  return (
    <div>
      <h1>Moser in de tijd</h1>
      { days.map((day, i) => {
        return <div className="day" key={day.date}>
          <div className="label">{ day.date.substring(5) } ({ day.times.length })</div>
          <div className="times">
            { day.times.map((time, j) => {
              const returnArray = []

              if(j === 0) {
                if(prevDiff === null){
                  prevDiff = 0
                }
                restDiff = (new Date(`${day.date}T${time}:00Z`) - new Date(`${day.date}T00:00:00Z`))/ 3600000
                returnArray.push(<span className="time" style={{ width: `${restDiff/24*100}%`, backgroundColor: `rgba(0,120,110,${prevDiff/6})` }} key={`time-${j}-prev`}>
                  <span className="time-begin">{ prevTime }</span>
                </span>)
              }

              let nextDate = day.date
              if(j === day.times.length-1 && days[i+1]) {
                nextDate = days[i+1].date
              }

              let nextTime = time
              if(j === day.times.length-1 && days[i+1]) {
                nextTime = days[i+1].times[0]
              } else if(day.times[j+1]) {
                nextTime = day.times[j+1]
              }
              const diff = (new Date(`${nextDate}T${nextTime}:00Z`) - new Date(`${day.date}T${time}:00Z`))/ 3600000

              let plotDiff
              if(nextDate === day.date) {
                plotDiff = (new Date(`${nextDate}T${nextTime}:00Z`) - new Date(`${day.date}T${time}:00Z`))/ 3600000
              } else {
                plotDiff = (new Date(`${nextDate}T00:00:00Z`) - new Date(`${day.date}T${time}:00Z`))/ 3600000
              }

              returnArray.push(<span className="time" style={{ width: `${plotDiff/24*100}%`, backgroundColor: `rgba(0,120,110,${diff/6})` }} key={`time-${j}`} onClick={ e => { alert(diff) } }>
                <span className="time-begin">{ time }</span>
                {/* <span className="time-diff">{ diff }</span> */}
                { plotDiff > 3 && j === day.times.length-1 && <span className="time-end">{ nextTime }</span>}
              </span>)

              if(j === day.times.length - 1) {
                restDiff = (new Date(`${day.date}T${time}:00Z`) - new Date(`${day.date}T00:00:00Z`).addHours(1)) / 3600000;
                prevDiff = diff
                prevTime = time
                //returnArray.push(<span className="time" style={{ width: `${ (diff-restDiff) /24*100}%`, backgroundColor: `rgba(0,120,110,${diff/6})` }} key={`time-${j}-rest`}></span>)
              }

              return returnArray
            })}
          </div>
        </div>
      })
      .reverse() 
    }
    </div>
  );
}

